import React from 'react'
import { Link } from 'react-router-dom'
import Fade from 'react-reveal/Fade';

import { Helmet } from 'react-helmet'

import NavigationLinks from '../components/navigation-links'
import './reed-difuser.css'

const ReedDifuser = (props) => {
  return (
    <div className="reed-difuser-container">
      <Helmet>
        <title>ReedDifuser - SAFGO</title>
        <meta property="og:title" content="ReedDifuser - SAFGO" />
      </Helmet>
      <div className="reed-difuser-container01">
        <div className="reed-difuser-container02"></div>
        <header data-role="Header" className="reed-difuser-header">
          <Link to="/" className="reed-difuser-navlink">
            <img
              alt="image"
              src="/logo/logo-removebg-preview1-200h.png"
              className="reed-difuser-image"
            />
          </Link>
          <div className="reed-difuser-nav">
            <NavigationLinks
              text="About us"
              text1="Product"
              text2="FAQ"
              text3="Contact us"
              rootClassName="rootClassName24"
            ></NavigationLinks>
          </div>
          <div data-role="BurgerMenu" className="reed-difuser-burger-menu">
            <svg viewBox="0 0 1024 1024" className="reed-difuser-icon">
              <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
            </svg>
          </div>
          <div data-role="MobileMenu" className="reed-difuser-mobile-menu">
            <div className="reed-difuser-nav1">
              <div className="reed-difuser-container03">
                <img
                  alt="image"
                  src="https://presentation-website-assets.teleporthq.io/logos/logo.png"
                  className="reed-difuser-image1"
                />
                <div
                  data-role="CloseMobileMenu"
                  className="reed-difuser-close-mobile-menu"
                >
                  <svg viewBox="0 0 1024 1024" className="reed-difuser-icon02">
                    <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                  </svg>
                </div>
              </div>
              <NavigationLinks rootClassName="rootClassName25"></NavigationLinks>
            </div>
            <div>
              <svg
                viewBox="0 0 950.8571428571428 1024"
                className="reed-difuser-icon04"
              >
                <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
              </svg>
              <svg
                viewBox="0 0 877.7142857142857 1024"
                className="reed-difuser-icon06"
              >
                <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
              </svg>
              <svg
                viewBox="0 0 602.2582857142856 1024"
                className="reed-difuser-icon08"
              >
                <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
              </svg>
            </div>
          </div>
        </header>
      </div>
      
      <div className="reed-difuser-container04">
        <div className="reed-difuser-container05">
          <div className="reed-difuser-container06">
            <div className="reed-difuser-container07"></div>
            <div className="reed-difuser-container08">
              <Fade>
              <span className="reed-difuser-text">
                <span className="reed-difuser-text01">Rattan </span>
                <span className="reed-difuser-text02">Reed Diffuser Stick</span>
              </span>
              </Fade>
            </div>
          </div>
        </div>
        <div className="reed-difuser-container09">
          <div className="reed-difuser-container10">
            <div className="reed-difuser-container11">
              <div className="reed-difuser-container12">
                <span className="reed-difuser-text03">
                  <span className="reed-difuser-text04">Rattan canes</span>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <Fade>
                  <span className="reed-difuser-text06">
                    are derived from any one of a number of species of palms.
                    They provide a spectacular sight as they can sometimes grow
                    up to several hundred feet tall. Aside from this spectacle,
                    one of the reasons these types of waling aids are so popular
                    is because they offer fantastic durability. This durability
                    makes these items one of the absolute best values on the
                    market. As part of this durability, they also offer a
                    fantastic resistance to splintering. Sections of rattan are
                    separated from the main plant along a smooth plane which
                    provides a structurally sound cut. This means you get to
                    enjoy immense structural integrity from them. You can take
                    on some challenging daily walks or longer treks and truly
                    enjoy a boost in confidence knowing that your weight is well
                    supported
                  </span>
                  </Fade>
                </span>
              </div>
            </div>
          </div>
          <div className="reed-difuser-container13">
            <div className="reed-difuser-container14">
              <Fade left>
              <img
                src="product\stick\IMG_0264.jpg"
                className="reed-difuser-image2"
              />
              <img
                src="product\stick\IMG_0261.jpg"
                className="reed-difuser-image3"
              />
              <img
                src="product\stick\IMG_1423.PNG"
                className="reed-difuser-image4"
              />
              </Fade>
            </div>
          </div>
          <div className="reed-difuser-container15">
            <div className="reed-difuser-container16">
              <div className="reed-difuser-container17">
                <span className="reed-difuser-text07">
                  <Fade>
                  <span>
                    Rattan is a vine that grows in the tropical forests of the
                    Far East . Growing hundreds of feet in length, it is one of
                    nature&apos;s strongest materials. It will not splinter or
                    break and is ideal for making furniture. Once molded, solid
                    rattan retains its contours permanently. Peel, is the outer
                    skin of the rattan pole and traditionally is used to wrap
                    joints. Wicker refers to products that are made from reed,
                    cane, rush, willow or other natural materials. When wet,
                    these materials are pliable enough to be woven.
                  </span>
                  </Fade>
                  <br></br>
                  <br></br>
                  <Fade>
                  <span>
                    Our diffuser reeds are made from sticks. A natural plant
                    material. Each stick has tiny channels that run from one end
                    to another that help in drawing the fragrance oil and
                    diffusing it in the air ensuring the best user experience.
                    Our reeds are available with our diffusers oils and also by
                    itself as a side purchase. The quality of the scent throw in
                    the air highly depends on the quality of the oil and the
                    reeds.
                  </span>
                  </Fade>
                  <br></br>
                </span>
              </div>
            </div>
          </div>
          <div className="reed-difuser-container18">
            <Fade right>
            <img
              alt="image"
              src="product\stick\IMG_0989.jpg"
              className="reed-difuser-image5"
            />
            <div className="reed-difuser-container19">
              <div className="reed-difuser-container20">
                <img
                  alt="image"
                  src="product\stick\IMG_0285.jpg"
                  className="reed-difuser-image6"
                />
                <img
                  alt="image"
                  src="product\stick\IMG_1422.PNG"
                  className="reed-difuser-image7"
                />
              </div>
              <div className="reed-difuser-container21">
                <img
                  alt="image"
                  src="product\stick\IMG_1425.PNG"
                  className="reed-difuser-image8"
                />
              </div>
            </div>
            </Fade>
          </div>
          <div className="reed-difuser-container22">
            <div className="reed-difuser-container23">
              <div className="reed-difuser-container24">
                <span className="reed-difuser-text13">
                  <Fade bottom>
                  <span>
                    Safgo offers a set of rattan reed diffuser sticks that are
                    perfect for adding a long-lasting fragrance to any room.
                    Made of natural rattan, these sticks are highly absorbent
                    and can effectively diffuse essential oils and other scented
                    liquids into the surrounding air. They are easy to use,
                    simply insert the sticks into a fragrance-filled container
                    and let the wonderful aroma fill your space. These sticks
                    are also durable and can be easily cleaned for reuse. They
                    come in a pack of 7 Pcs, making them an excellent value for
                    anyone looking to enhance the atmosphere of their home or
                    office.
                  </span>
                  </Fade>
                  <br className="reed-difuser-text15"></br>
                  <br className="reed-difuser-text16"></br>
                  <Fade bottom>
                  <span>
                    These reed diffuser sticks are perfect for adding a subtle
                    fragrance to any room in your home. They come in both black
                    and natural colors, allowing you to choose the one that best
                    matches your decor. The reed sticks are made of high-quality
                    material that gently diffuses the fragrance oil without
                    overpowering the room. You just need to place them in a
                    diffuser bottle with your chosen fragrance oil, and they
                    will start dispensing a pleasant aroma that will last for
                    weeks. These sticks are affordable, easy to use, and
                    environmentally friendly compared to other fragrance
                    methods.
                  </span>
                  </Fade>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="reed-difuser-container25">
        <span className="reed-difuser-text18">
          Copyright © 2024 Safgo Ltd. All Rights Reserved
        </span>
      </div>
    </div>
  )
}

export default ReedDifuser
