import React from 'react'
import { Link } from 'react-router-dom'
import Fade from 'react-reveal/Fade';

import { Helmet } from 'react-helmet'

import NavigationLinks from '../components/navigation-links'
import './material-supply.css'

const MaterialSupply = (props) => {
  return (
    <div className="material-supply-container">
      <Helmet>
        <title>MaterialSupply - SAFGO</title>
        <meta property="og:title" content="MaterialSupply - SAFGO" />
      </Helmet>
      <div className="material-supply-container01">
        <div className="material-supply-container02"></div>
        <header data-role="Header" className="material-supply-header">
          <Link to="/" className="material-supply-navlink">
            <img
              alt="image"
              src="/logo/logo-removebg-preview1-200h.png"
              className="material-supply-image"
            />
          </Link>
          <div className="material-supply-nav">
            <NavigationLinks
              text="About us"
              text1="Product"
              text2="FAQ"
              text3="Contact us"
              rootClassName="rootClassName26"
            ></NavigationLinks>
          </div>
          <div data-role="BurgerMenu" className="material-supply-burger-menu">
            <svg viewBox="0 0 1024 1024" className="material-supply-icon">
              <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
            </svg>
          </div>
          <div data-role="MobileMenu" className="material-supply-mobile-menu">
            <div className="material-supply-nav1">
              <div className="material-supply-container03">
                <img
                  alt="image"
                  src="https://presentation-website-assets.teleporthq.io/logos/logo.png"
                  className="material-supply-image1"
                />
                <div
                  data-role="CloseMobileMenu"
                  className="material-supply-close-mobile-menu"
                >
                  <svg
                    viewBox="0 0 1024 1024"
                    className="material-supply-icon02"
                  >
                    <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                  </svg>
                </div>
              </div>
              <NavigationLinks rootClassName="rootClassName27"></NavigationLinks>
            </div>
            <div>
              <svg
                viewBox="0 0 950.8571428571428 1024"
                className="material-supply-icon04"
              >
                <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
              </svg>
              <svg
                viewBox="0 0 877.7142857142857 1024"
                className="material-supply-icon06"
              >
                <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
              </svg>
              <svg
                viewBox="0 0 602.2582857142856 1024"
                className="material-supply-icon08"
              >
                <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
              </svg>
            </div>
          </div>
        </header>
      </div>
      <div className="material-supply-container04">
        <div className="material-supply-container05">
          <div className="material-supply-container06">
            <div className="material-supply-container07">
              <span className="material-supply-text">
                <Fade left>
                <span className="material-supply-text01">
                  Natural Material 
                </span>
                </Fade>
                <Fade right>
                <span className="material-supply-text02">Supply</span>
                </Fade>
              </span>
            </div>
          </div>
        </div>
        <div className="material-supply-container08">
          <div className="material-supply-container09">
            <div className="material-supply-container10">
              <div className="material-supply-container11"></div>
            </div>
          </div>
          <div className="material-supply-container12">
            <Fade left>
            <img
              alt="image"
              src="\product\material\1.JPG"
              className="material-supply-image2"
            />
            </Fade>
            <Fade right>
            <div className="material-supply-container13">
              <span className="material-supply-text03">
                <span className="material-supply-text04">WATER HYACINTH</span>
                <br className="material-supply-text05"></br>
                <br className="material-supply-text06"></br>
                <span className="material-supply-text07">
                  {' '}
                  is a free-floating perennial plant that can grow to a height
                  of 3 feet. The dark green leave blades are circular to
                  elliptical in shape attached to a spongy, inflated petiole.
                  Underneath the water is a thick, heavily branched, dark
                  fibrous root system. The water hyacinth has striking light
                  blue to violet flowers located on a terminal spike. Water
                  hyacinth is a very aggressive invader and can form thick mats.
                  If these mats cover the entire surface of the pond they can
                  cause oxygen depletions and fish kills. Water hyacinths should
                  be controlled so they do not cover the entire pond.
                </span>
                <br className="material-supply-text08"></br>
                <br className="material-supply-text09"></br>
                <span className="material-supply-text10">
                  In Indonesia, this plant is also used as basic material for
                  producing furniture, basket, and others handicraft goods.
                </span>
                <br className="material-supply-text11"></br>
                <br className="material-supply-text12"></br>
                <span className="material-supply-text13">
                  We made this material as a kind of rope. You can choose a
                  braided or not .A braided Water hyacinth material has diameter
                  size :5/8mm, 8/10mm and 10/12mm.
                </span>
              </span>
            </div>
            </Fade>
          </div>
          <div className="material-supply-container14">
            <div className="material-supply-container15">
            <Fade left>
              <span className="material-supply-text14">
                <span className="material-supply-text15">SEAGRASS ROPE</span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br className="material-supply-text17"></br>
                <br className="material-supply-text18"></br>
                <span className="material-supply-text19">
                  material is made by skillful craftsman. Seagrass is one of
                  grass that grow in paddy field. They usually have less than
                  100cm length. Once harvested, the seagrass is dried and then
                  spun into a strong yarn ready for weaving. Our material have
                  high quality and durability.
                </span>
                <br className="material-supply-text20"></br>
                <br className="material-supply-text21"></br>
                <br className="material-supply-text22"></br>
                <span className="material-supply-text23">
                  The availability diameter are: 2/3mm, 3/4mm and 5/6mm. Average
                  weight each bundle : 25-30Kgs.
                  
                </span>
              </span>
              </Fade>
            </div>
            <Fade right>
            <img
              alt="image"
              src="\product\material\3.JPG"
              className="material-supply-image3"
            />
            </Fade>
          </div>
          <div className="material-supply-container16">
            <Fade left>
            <img
              alt="image"
              src="\product\material\060035a0-8081-4124-8e12-df8f76e1abbd.jpg"
              className="material-supply-image4"
            />
            </Fade>
            <Fade right>
            <div className="material-supply-container17">
              <span className="material-supply-text24">
                <span className="material-supply-text25">BANANA TWISTED</span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
                <br></br>
                <span className="material-supply-text29">
                  We pride ourselves on the integrity and quality of our
                  material will help guiding you in the decisions that will give
                  you as much satisfaction as it does us.
                </span>
                <br></br>
                <br></br>
                <span className="material-supply-text29">
                The fibers extracted from banana stalks are meticulously transformed into robust ropes, exuding a natural allure and strength. This eco-friendly material reflects a harmonious blend of tradition and innovation, showcasing the inherent durability and flexibility of banana plant fibers
                </span>
                <br></br>
                <br></br>
                <span className="material-supply-text29">
                Adaptable and sturdy, banana twisted ropes find applications across diverse domains, from artisanal handicrafts to agricultural practices and even in eco-conscious home decor. Their rustic charm and reliability echo the natural essence of the plant, offering a sustainable alternative in various industries.
                </span>
                <br></br>
                <br></br>
                <span className="material-supply-text29">
                Crafted with a reverence for nature's gifts, banana twisted ropes not only embody resilience and strength but also symbolize the artistry born from repurposing organic resources. Their versatility and eco-friendly attributes make them a fitting choice for those who value sustainability and craftsmanship intertwined with natural elements
                </span>
              </span>
            </div>
            </Fade>
          </div>
        </div>
      </div>
      <div className="material-supply-container18">
        <span className="material-supply-text30">
          Copyright © 2024 Safgo Ltd. All Rights Reserved
        </span>
      </div>
    </div>
  )
}

export default MaterialSupply
