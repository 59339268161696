import React from 'react'
import { Link } from 'react-router-dom'
import Fade from 'react-reveal/Fade';

import 'react-slideshow-image/dist/styles.css'
import { Fade as Slide } from "react-slideshow-image";

import { Helmet } from 'react-helmet'

import NavigationLinks from '../components/navigation-links'
import './home.css'

import MyImage1 from './1.png';
import MyImage2 from './2.png';
import MyImage3 from './3.png';

const fadeImages = [
  MyImage1,
  MyImage2,
  MyImage3
];

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>SAFGO</title>
        <meta property="og:title" content="SAFGO" />
      </Helmet>

      <div className="slide-container">
      <Slide>
        <div className="each-fade">
          <img src={fadeImages[0]} />
        </div>
        <div className="each-fade">
          <img src={fadeImages[1]} />
        </div>
        <div className="each-fade">
          <img src={fadeImages[2]} />
        </div>
      </Slide>
      <div className="home-container01"></div>
      
      <a href='https://wa.me/6281321981971'>

      <button className='btn-floating'>
        <img src='/whatsapp-logo.png' className='floating-icon' alt="wa"/>
        <span>+62 813-2198-1971</span>
      </button>

      </a>

      <a href='https://www.instagram.com/safgorattanstickdiffuser/'>

      <button className='btnig-floating'>
        <img src='/instagram.png' className='floating-icon' alt="wa"/>
        <span>@safgorattanstickdiffuser</span>
      </button>

      </a>

        <header data-role="Header" className="home-header">
          <img
            alt="image"
            src="/logo/logo-removebg-preview1-200h.png"
            className="home-image"
          />
          <div className="home-nav">
            <NavigationLinks
              text="About us"
              text1="Product"
              text2="FAQ"
              text3="Contact us"
              rootClassName="rootClassName12"
            ></NavigationLinks>
          </div>
          <div data-role="BurgerMenu" className="home-burger-menu">
            <svg viewBox="0 0 1024 1024" className="home-icon">
              <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
            </svg>
          </div>
          <div data-role="MobileMenu" className="home-mobile-menu">
            <div className="home-nav1">
              <div className="home-container03">
                <img
                  alt="image"
                  src="https://presentation-website-assets.teleporthq.io/logos/logo.png"
                  className="home-image01"
                />
                <div
                  data-role="CloseMobileMenu"
                  className="home-close-mobile-menu"
                >
                  <svg viewBox="0 0 1024 1024" className="home-icon02">
                    <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                  </svg>
                </div>
              </div>
              <NavigationLinks rootClassName="rootClassName13"></NavigationLinks>
            </div>
            <div>
              <svg viewBox="0 0 950.8571428571428 1024" className="home-icon04">
                <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
              </svg>
              <svg viewBox="0 0 877.7142857142857 1024" className="home-icon06">
                <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
              </svg>
              <svg viewBox="0 0 602.2582857142856 1024" className="home-icon08">
                <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
              </svg>
            </div>
          </div>
        </header>
      </div>
      <div className="home-container04">
        <span className="home-text04">
          <br className="home-text05"></br>
          <span className="home-text06">
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <Fade>
          <span className="home-text07">Safgo Ltd</span>
          <span className="home-text08">
            {' '}
            is a reputable company that specializes in the provision of
            high-quality rattan diffuser sticks to clients. Since its
            establishment, the company has grown to become a trusted partner in
            rattan reed diffuser sticks industry, by offering innovative and cost-effective solutions to
            meet the clients&apos; needs. With the dedication of a
            highly-skilled team of professionals, Safgo Ltd constantly strives
            to enhance its customer experience by providing reliable, efficient,
            and personalized services. The company&apos;s commitment to
            excellence has earned it a strong reputation and a loyal client
            base.
          </span>
          </Fade>
          
          <br className="home-text09"></br>
          <br className="home-text10"></br>
          <span className="home-text11">
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
        </span>
      </div>
      <div className="home-container05">
        <span className="home-text12">ABOUT US</span>
        <Link to="/about" className="home-navlink">
          VIEW
        </Link>
      </div>
      <div className="home-container06">
        <div className="home-container07">
          <span className="home-text13">
            <span>SAFGO Product</span>
            <br></br>
          </span>
          
        </div>
        <div className="home-container08">
          <Link to="/reed-difuser" className="home-navlink2">
            <div className="product-container07-foree">
              <div className="product-container07-fore">
                <span>Rattan Reed Diffuser Stick</span>
              </div>
              <img
                alt="image"
                src="home\DSC05262.jpg"
                className="home-image02"/>
            </div>
            
          </Link>
          <div className="home-container09">
            <div className="home-container10">
              <Link to="/material-supply" className="home-navlink3">
                <div className="product-container08-foree">
                <div className="product-container08-fore">
                  <span>Natural Material Supply</span>
                </div>
                <img
                  alt="image"
                  src="product\material\IMG_4603.JPG"
                  className="home-image03"/>
                </div>
              </Link>
              
            </div>
            <div className="home-container11">
              <Link to="/rattan-webbing" className="home-navlink4">
                <div className="product-container09-foree">
                <div className="product-container09-fore">
                  <span>Rattan Webbing</span>
                </div>
                <img
                  alt="image"
                  src="product\webbing\IMG_4601.JPG"
                  className="home-image04"/>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="home-container12">
        <div className="home-container13">
          <span className="home-text23">
            <span>OUR CLIENTS</span>
            <br></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </span>
        </div>
        <div className="home-container14">
        <Fade left distance="10%" duration={1500}>
          <img
            alt="image"
            src="/logo/johnson%20indonesia-200h.png"
            className="home-image05"
          />
          <img
            alt="image"
            src="/logo/logo%20vic-200h.jpeg"
            className="home-image06"
          />
          <img
            alt="image"
            src="/logo/logo-aulia-cosmetic-indonesia-01-200h.png"
            className="home-image07"
          />
          <img
            alt="image"
            src="/logo/euodiahome-200h.png"
            className="home-image08"
          />
          <img
            alt="image"
            src="/logo/camani-200h.jpg"
            className="home-image09"
          />
          <img
            alt="image"
            src="/logo/bathaholic-200h.png"
            className="home-image10"
          />
          <img
            alt="image"
            src="/logo/airwick.jpg"
            className="home-image10"
          />
          <img
            alt="image"
            src="/logo/Zara-Home-Logo.jpg"
            className="home-image10"
          />
          </Fade>
        </div>
      </div>
      <div id="faq" className="home-container15">
        <span className="home-text27">
          <span className="home-text28">FAQ</span>
          <br className="home-text29"></br>
          <br className="home-text30"></br>
          <span>•What is the minimum order ?</span>
          <br className="home-text32"></br>
          <span className="home-text33">t</span>
          <span className="home-text34">
            he minimum order for rattan reed diffuser stick 5kg. Banana fiber
            300kg
          </span>
          <span className="home-text35">. Water hyacinth 300kg</span>
          <br className="home-text36"></br>
          <br className="home-text37"></br>
          <span>•Delivery time ?</span>
          <br className="home-text39"></br>
          <span className="home-text40">
            the production time depends on the complexity of each order and the
            payment. Normally the delivery time is 30-45 days after confirmed
            order. Large order could be longer.
          </span>
          <br className="home-text41"></br>
          <br className="home-text42"></br>
          <span>•What is the payment terms?</span>
          <br className="home-text44"></br>
          <span className="home-text45">
            Our price based on FOB Tanjung Priok Port Jakarta. Payment could be
            made in US Dollar or Rupiah. Please inform us before &amp; after
            sending any payment to our bank account. We give following options
            for payment:
          </span>
          <br className="home-text46"></br>
          <br className="home-text47"></br>
          <span>1) T/T (Telegraphic Transfer) :</span>
          <br className="home-text49"></br>
          <span className="home-text50">
            50 % Down payment or advance transferred before production.
          </span>
          <br className="home-text51"></br>
          <span className="home-text52">
            50% Balance payment transferred after shipment (payment against
            documents—sending a copy by fax or email).
          </span>
          <br className="home-text53"></br>
          <br className="home-text54"></br>
          <span>2) Irrevocable confirmed L/C at sight</span>
          <br className="home-text56"></br>
          <br className="home-text57"></br>
          <span>•Prices ?</span>
          <br className="home-text59"></br>
          <span className="home-text60">Negotiable.</span>
          <span>
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <br className="home-text62"></br>
          <br className="home-text63"></br>
          <span>•Do you show your price list on your website ?</span>
          <br className="home-text65"></br>
          <span className="home-text66">
            No, we don’t. We will give you separately based on request.
          </span>
          <br className="home-text67"></br>
          <br className="home-text68"></br>
          <span>•How do you handle complaints?</span>
          <br className="home-text70"></br>
          <span className="home-text71">
            Please send us the detailed information about the complaints then we
            will give you the best solutions.
          </span>
          <br className="home-text72"></br>
          <br className="home-text73"></br>
          <span className="home-text74">
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
        </span>
      </div>
      <div id="contact" className="home-container16">
        <span className="home-text75">
          <span className="home-text76">CONTACT US</span>
          <br className="home-text77"></br>
          <br className="home-text78"></br>
          <br className="home-text79"></br>
          <span>Office Address:</span>
          <br></br>
          <span>Jl Andir R. I. Winata Dalam No 133/79.</span>
          <br></br>
          <span>Bandung City. INDONESIA</span>
          <br></br>
          <br></br>
          <span>Factory Address:</span>
          <br></br>
          <span>Jl Jl Raya Bandung-Garut.</span>
          <br></br>
          <span>Warung Peuteuy. Kab Garut</span>
          <br></br>
          <br></br>
          <span>Phone: +62 22 20564259</span>
          <br className="home-text86"></br>
          <span>Fax: +62 22 20564259</span>
          <br className="home-text88"></br>
          <span>Email: info@safgo.com</span>
          <br className="home-text90"></br>
          <span>Backup e-mail: safgoindonesia@gmail.com</span>
          <br className="home-text92"></br>
          <span>Website: www.safgo.com</span>
          <br className="home-text94"></br>
          <span>Skype: safgo.co.ltd</span>
          <span className="home-text96">
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
        </span>
      </div>
      <div className="home-container17">
        <span className="home-text97">
          Copyright © 2024 Safgo Ltd. All Rights Reserved
        </span>
      </div>
    </div>
  )
}

export default Home
