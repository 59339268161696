import React from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

import './style.css'
import About from './views/about'
import ReedDifuser from './views/reed-difuser'
import RattanWebbing from './views/rattan-webbing'
import Basket from './views/basket'
import MaterialSupply from './views/material-supply'
import Home from './views/home'
import NotFound from './views/not-found'

const App = () => {
  return (
    <Router>
      <Switch>
        <Route component={About} exact path="/about" />
        <Route component={ReedDifuser} exact path="/reed-difuser" />
        <Route component={RattanWebbing} exact path="/rattan-webbing" />
        <Route component={Basket} exact path="/basket" />
        <Route component={MaterialSupply} exact path="/material-supply" />
        <Route component={Home} exact path="/" />
        <Route component={NotFound} path="**" />
        <Redirect to="**" />
      </Switch>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
